import React from "react";
import { useOverrides } from "@quarkly/components";
import { Image, LinkBox, Link, Box, Text, Section } from "@quarkly/widgets";
const defaultProps = {
	"padding": "80px 0 30px 0",
	"background": "--color-dark",
	"quarkly-title": "Footer-18"
};
const overrides = {
	"box": {
		"kind": "Box",
		"props": {
			"display": "flex",
			"margin": "0px 0px 30px 0px",
			"md-margin": "0px 0px 45px 0px",
			"lg-flex-direction": "row",
			"lg-flex-wrap": "wrap",
			"lg-margin": "0px 0px 0 0px",
			"align-items": "flex-start"
		}
	},
	"linkBox": {
		"kind": "LinkBox",
		"props": {
			"width": "18%",
			"lg-margin": "0px 0px 35px 0px",
			"lg-width": "40%",
			"sm-width": "100%",
			"href": "#"
		}
	},
	"image": {
		"kind": "Image",
		"props": {
			"src": "https://sichheritage.com/img/landscape-logo.png",
			"display": "block",
			"align-self": "flex-start",
			"width": "140px"
		}
	},
	"box1": {
		"kind": "Box",
		"props": {
			"min-width": "100px",
			"min-height": "100px",
			"display": "flex",
			"grid-template-columns": "repeat(2, 1fr)",
			"grid-gap": "54px",
			"lg-align-items": "start",
			"md-grid-template-columns": "repeat(3, 1fr)",
			"md-grid-gap": "36px 34px",
			"sm-grid-template-columns": "1fr",
			"sm-grid-gap": "16px 0",
			"justify-content": "center",
			"sm-flex-direction": "column",
			"width": "40%",
			"lg-width": "60%",
			"sm-width": "100%",
			"sm-margin": "0px 0px 25px 0px",
			"lg-justify-content": "flex-end",
			"md-width": "100%",
			"md-justify-content": "flex-start",
			"sm-display": "flex"
		}
	},
	"box2": {
		"kind": "Box",
		"props": {
			"align-items": "flex-start",
			"margin": "0px 0px 0px 0",
			"lg-align-items": "flex-start",
			"justify-content": "flex-start",
			"display": "grid",
			"lg-flex-direction": "column",
			"lg-margin": "0px 0px 0px 0px",
			"flex-direction": "column",
			"grid-gap": "10px 0",
			"align-content": "start",
			"sm-grid-gap": "16px"
		}
	},
	"link": {
		"kind": "Link",
		"props": {
			"border-color": "--color-primary",
			"display": "flex",
			"font": "normal 400 16px/24px --fontFamily-googleRoboto",
			"margin": "0px 0 0px 0",
			"lg-border-width": "0px 0px 0px 2px",
			"href": "#",
			"text-decoration-line": "initial",
			"color": "#c3c8d0",
			"hover-color": "#ffffff",
			"letter-spacing": "1px",
			"children": "Головна"
		}
	},
	"link1": {
		"kind": "Link",
		"props": {
			"margin": "0px 0 0px 0",
			"display": "flex",
			"href": "#",
			"font": "normal 400 16px/24px --fontFamily-googleRoboto",
			"text-decoration-line": "initial",
			"color": "#c3c8d0",
			"hover-color": "#ffffff",
			"letter-spacing": "1px",
			"children": "Наші Послуги"
		}
	},
	"link2": {
		"kind": "Link",
		"props": {
			"margin": "0px 0 0px 0",
			"hover-color": "#ffffff",
			"href": "#",
			"text-decoration-line": "initial",
			"color": "#c3c8d0",
			"font": "normal 400 16px/24px --fontFamily-googleRoboto",
			"display": "flex",
			"letter-spacing": "1px",
			"children": "Контакти"
		}
	},
	"box3": {
		"kind": "Box",
		"props": {
			"min-width": "100px",
			"min-height": "100px",
			"display": "flex",
			"lg-margin": "40px 0px 0 0px",
			"sm-flex-direction": "column",
			"sm-margin": "30px 0px 0 0px"
		}
	},
	"box4": {
		"kind": "Box",
		"props": {
			"min-width": "100px",
			"min-height": "100px",
			"margin": "0px 30px 0px 0px",
			"lg-margin": "0px 50px 0px 0px",
			"display": "flex",
			"flex-direction": "column"
		}
	},
	"text": {
		"kind": "Text",
		"props": {
			"margin": "0px 0px 25px 0px",
			"font": "normal 500 18px/1.2 --fontFamily-sans",
			"color": "--light",
			"md-margin": "0px 0px 20px 0px",
			"letter-spacing": "1px",
			"children": "Центр звʼязку:"
		}
	},
	"link3": {
		"kind": "Link",
		"props": {
			"href": "mailto:bulbakhandrey@gmail.com",
			"color": "#c3c8d0",
			"text-decoration-line": "initial",
			"font": "normal 300 16px/1.5 --fontFamily-sans",
			"display": "inline-block",
			"margin": "0px 0px 15px 0px",
			"white-space": "nowrap",
			"children": "bulbakhandrey@gmail.com"
		}
	},
	"box5": {
		"kind": "Box",
		"props": {
			"min-width": "100px",
			"min-height": "100px"
		}
	},
	"text1": {
		"kind": "Text",
		"props": {
			"margin": "0px 0px 25px 0px",
			"font": "normal 500 18px/1.2 --fontFamily-sans",
			"color": "--light",
			"md-margin": "0px 0px 20px 0px",
			"letter-spacing": "1px",
			"children": "Адреса:"
		}
	},
	"text2": {
		"kind": "Text",
		"props": {
			"margin": "0px 0px 20px 0px",
			"color": "#c3c8d0",
			"font": "normal 300 16px/1.5 --fontFamily-sans",
			"border-color": "#b8acac",
			"md-margin": "0px 0px 15px 0px",
			"children": <>
				{" "}Полтавська область, 39140,{"\n\t\t\t\t\t "}
				<br />
				смт Нова Галещина,{" "}
				<br />
				вулиця Горького, 52
			</>
		}
	},
	"box6": {
		"kind": "Box",
		"props": {
			"display": "flex",
			"justify-content": "space-between",
			"border-color": "#232a44",
			"md-flex-direction": "column",
			"lg-padding": "30px 0px 0px 0px",
			"md-padding": "0 0px 0px 0px"
		}
	},
	"box7": {
		"kind": "Box",
		"props": {
			"display": "flex",
			"grid-template-columns": "repeat(5, 1fr)",
			"grid-gap": "16px 24px",
			"md-align-self": "flex-start",
			"md-margin": "0px 0px 15px 0px"
		}
	},
	"text3": {
		"kind": "Text",
		"props": {
			"margin": "0px 0px 0px 0px",
			"font": "--base",
			"color": "#c3c8d0",
			"children": "ТОВ НВП «ГАЛЕЩИНА-АГРО» © 2024 All rights reserved."
		}
	}
};

const Footer = props => {
	const {
		override,
		children,
		rest
	} = useOverrides(props, overrides, defaultProps);
	return <Section {...rest}>
		<Box {...override("box")}>
			<LinkBox {...override("linkBox")}>
				<Image {...override("image")} />
			</LinkBox>
			<Box {...override("box1")}>
				<Box {...override("box2")}>
					<Link {...override("link")} />
					<Link {...override("link1")} />
					<Link {...override("link2")} />
				</Box>
			</Box>
			<Box {...override("box3")}>
				<Box {...override("box4")}>
					<Text {...override("text")} />
					<Link {...override("link3")} />
				</Box>
				<Box {...override("box5")}>
					<Text {...override("text1")} />
					<Text {...override("text2")} />
				</Box>
			</Box>
		</Box>
		<Box {...override("box6")}>
			<Box {...override("box7")} />
			<Text {...override("text3")} />
		</Box>
		{children}
	</Section>;
};

Object.assign(Footer, { ...Section,
	defaultProps,
	overrides
});
export default Footer;